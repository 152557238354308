.app-page {
  height: 100%;

  .app-page-header {
    padding: 16px 0;

    @apply mb-3;
    // @apply grid;
    // @apply grid-cols-1 md:grid-cols-2;

    @apply block md:flex;
    @apply items-center;
    @apply justify-between;

    @apply gap-3;

    .app-page-title {
      @apply flex;
      @apply items-center;
      @apply mb-3 md:mb-0;
    }

    .app-page-header-actions {
      @apply text-center md:text-end flex-shrink-0;

      .app-btn {
        @apply w-full md:w-auto;
      }
    }
  }
}
