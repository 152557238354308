body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#root {
  @apply bg-slate-100;
}

form {
  .form-actions {
    border-top: 1px solid var(--disabled);
    padding-top: 16px;
    margin-top: 16px;
    gap: 16px;
  }
}

.app-badge {
  @apply badge;
  @apply rounded-lg;
  @apply font-bold;
  @apply text-xs;
  @apply border-none;

  height: 24px;

  &.badge-light-primary {
    @apply text-primary;
    background-color: var(--primary-light);
  }

  &.badge-light-success {
    @apply text-success;
    background-color: var(--success-light);
  }

  &.badge-light-warning {
    @apply text-warning;
    background-color: var(--warning-light);
  }

  &.badge-light-danger {
    @apply text-error;
    background-color: var(--danger-light);
  }
}

.card {
  @apply bg-base-100;
  @apply shadow;
}

a {
  @apply hover:text-primary;
}

.no-results {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-muted);
}
