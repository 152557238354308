@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "./colors.scss";

@import "./variables.scss";

@import "./typography.scss";

@import "./global.scss";
