@font-face {
	font-family: "Montserrat";
	src: url("../assets/fonts/Montserrat-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: "Montserrat";
	src: url("../assets/fonts/Montserrat-Regular.ttf");
	font-weight: 400;
}
@font-face {
	font-family: "Montserrat";
	src: url("../assets/fonts/Montserrat-Medium.ttf");
	font-weight: 500;
}
@font-face {
	font-family: "Montserrat";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: "Montserrat";
	src: url("../assets/fonts/Montserrat-Bold.ttf");
	font-weight: bold;
}

html,
body {
	@apply text-base;
	@apply font-normal;
	@apply text-gray-700;
}

h1,
.h1 {
	@apply text-6xl;
}
h2,
.h2 {
	@apply text-5xl;
}
h3,
.h3 {
	@apply text-3xl;
}
h4,
.h4 {
	@apply text-2xl;
}
h5,
.h5 {
	@apply text-xl;
}
h6,
.h6 {
	@apply text-lg;
}

// styles
.page-title {
	@apply h3;
	@apply text-semibold;
	@apply mb-6;
}
.section {
	@apply text-sm;
	@apply font-semibold;
}

// colors
.text-muted {
	@apply text-gray-400;
}

// weight
.text-semibold {
	@apply font-semibold;
}
.text-normal {
	@apply font-normal;
}
.text-medium {
	@apply font-medium;
}
.text-bold,
b {
	@apply font-bold;
}
.text-extrabold,
b {
	@apply font-extrabold;
}
