.app-chip {
	width: fit-content;
	height: 32px;
	border-radius: 100px;
	padding: 4px 0 4px 12px;

	@apply flex;
	@apply items-center;
	@apply bg-gray-100;
	@apply text-sm;

	&.deletable {
		padding-right: 0;
	}

	.delete-btn {
		width: 24px;
		height: 24px;
		margin: 4px;

		@apply flex;
		@apply items-center;
		@apply justify-center;
		@apply rounded-full;

		&:hover {
			@apply bg-gray-300;
		}
	}
}
