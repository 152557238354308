.app-paginator {
	select {
		height: 30px;
		font-size: 12px;
	}

	.app-paginator_page-size {
		select {
			width: 80px;
		}
	}

	.app-paginator_page {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.app-paginator_page-selector {
			display: flex;
			align-items: center;

			.paginator {
				margin-bottom: 0;
				margin-right: 2px;

				.btn {
					width: 32px;
					padding: 1px 8px 2px 8px;
					margin: 0;
					border: none;
					user-select: none;
					margin-right: 2px;
					background-color: transparent;

					&.disabled {
						background-color: transparent;
					}

					&:focus {
						background-color: transparent;
						box-shadow: none;
					}

					&.active {
						background-color: transparent;
						@apply text-primary;

						border-width: 1px;
						@apply border-solid;
						@apply border-primary;
						@apply rounded-lg;
					}
				}
			}

			.select {
				width: auto;
			}
		}
	}
}
