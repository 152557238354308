:root {
  --primary: #073aa3;
  --primary-light: #f1faff;
  --primary-shade: #0095e8;
  --primary-contrast: #ffffff;

  --secondary: #3fd0f6;
  --secondary-shade: #003f30;
  --secondary-contrast: #ffffff;

  --light: #f9f9f9;
  --light-contrast: #78829d;

  --accent: #2b2a63;
  --accent-shade: #aa0054;
  --accent-contrast: #ffffff;

  --text: #353638;
  --text-muted: #99a1b7;

  --white: #ffffff;

  --success: #17c653;
  --success-light: #dfffea;

  --warning: #f6c000;
  --warning-light: #fff8dd;

  --danger: #f8285a;
  --danger-contrast: #ffffff;
  --danger-shade: #f63c3f;
  --danger-light: #ffeef3;

  --gray-100: #f9f9f9;
  --gray-100-rgb: 249, 249, 249;
  --gray-200: #f1f1f2;
  --gray-200-rgb: 241, 241, 242;
  --gray-300: #dbdfe9;
  --gray-300-rgb: 219, 223, 233;
  --gray-400: #b5b5c3;
  --gray-400-rgb: 181, 181, 195;
  --gray-500: #99a1b7;
  --gray-500-rgb: 153, 161, 183;
  --gray-600: #78829d;
  --gray-600-rgb: 120, 130, 157;
  --gray-700: #4b5675;
  --gray-700-rgb: 75, 86, 117;
  --gray-800: #252f4a;
  --gray-800-rgb: 37, 47, 74;
  --gray-900: #071437;
  --gray-900-rgb: 7, 20, 55;

  --background-color: #f8f9fc;

  --divider: #dee2e6;

  --border-color: #f4f4f4;

  --disabled: #dedede;

  --card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
}
