.image-library-choose-modal {
  .image-library-choose-modal__item {
    cursor: pointer;
    transition: all 0.3s;
    overflow: hidden;

    &:hover {
      opacity: 0.8;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    img {
      width: 100%;
      height: 125px;
      object-fit: cover;
    }
  }
}
